.App {
  text-align: left;
}

body {
  background-color: #282c34;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  min-height: 100vh;
  color: white;
}

.container {
  padding-top: 15px;
}

.header {
  font-size: 1.6em;
}

.sub-header {
  font-size: .8em;
}

.pad-me-top {
  padding-top: 15px;
}

.zip-text {
  width: 100%;
}


.test-border {
  border: solid 1px green;
}
.image-logo {
  width: 100px;
  margin-top: -25px;
  margin-bottom: -25px;
  margin-right: -25px;
  margin-left: -25px;
}

nav {
  color: white
}

.nav-link {
  color: white !important;
}

.test-border {
  border: solid 0px red;
}